/* eslint-disable no-unused-vars */

import FontAwesome from './theme/fontawesome';
import polyfills from './theme/utils/polyfills.js';

window.addEventListener('load', () => {
  polyfills();
  FontAwesome();

  // Query the DOM for dynamic imports


  const sliderElement = document.querySelector('#slider');

  const accordionElements = document.querySelectorAll('.accordion');

  if(accordionElements) {

    import('./theme/accordion' /* webpackChunkName: "accordion" */ /* webpackPrefetch: true */).then(
      module => {
        module.default(accordionElements);
      }
    );
  }

  if(sliderElement) {
    
    import('./theme/slider' /* webpackChunkName: "slider" */ /* webpackPrefetch: true */).then(
      module => {
        module.default(sliderElement);
      }
    );
  }

  import('./theme/navbar' /* webpackChunkName: "navbar" */ /* webpackPrefetch: true */).then(
    module => {
      module.default();
    }
  );
});
