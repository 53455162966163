import fontawesome from '@fortawesome/fontawesome';
//import light from '@fortawesome/fontawesome-pro-light';
//import solid from '@fortawesome/fontawesome-pro-solid';
//import regular from '@fortawesome/fontawesome-pro-regular';
//import brands from '@fortawesome/fontawesome-free-brands';
//import faChevron from '@fortawesome/fontawesome-pro-light/faChevronRight';
import faChevron from '@fortawesome/fontawesome-pro-regular/faChevronRight';
import faFile from '@fortawesome/fontawesome-pro-light/faFile';
import faPdf from '@fortawesome/fontawesome-pro-light/faFilePdf';
import faImage from '@fortawesome/fontawesome-pro-light/faFileImage';

const FontAwesome = function() {
  fontawesome.library.add(faChevron, faFile, faPdf, faImage);
};

export default FontAwesome;
